var Procare = function(CookieHandler) {
    var lgBreakpointEm = 62;
    var smBreakpointEm = 34;
    var searchPlaceholderBreakpointEm = 72;
    var searchGPPlaceholderBreakpointEm = 75;
    var wrapperPadding = 45;
    var maxWrapperWidth = 1290;

    var handleResize = function() {
        var em = parseFloat($("body").css("font-size"));
        var windowWidth = $(window).width();

        var searchPlaceholderBreakpoint = searchPlaceholderBreakpointEm * em;
        var searchGPPlaceholderBreakpoint = searchGPPlaceholderBreakpointEm * em;
        var lgBreakpoint = lgBreakpointEm * em;

        //change placeholder for search
        if (windowWidth < searchPlaceholderBreakpoint) {
            $('.main-nav .search input').attr('placeholder', 'Search ProCare');
        } else {
            $('.main-nav .search input').attr('placeholder', 'Search procare.co.nz');
        }

        //change placeholder for search GP
        if (windowWidth < searchGPPlaceholderBreakpoint && windowWidth > lgBreakpoint) {
            $('.search-form input').attr('placeholder', 'Name, practice or suburb');
        } else {
            $('.search-form input').attr('placeholder', 'Search by GP Name, practice or suburb');
        }

        //set full width margin if gone over max page width
        if (windowWidth < maxWrapperWidth) {
            $('.full-width')
                .css('margin-left', '')
                .css('margin-right', '')
                .css('padding-left', '')
                .css('padding-right', '');
        } else {
            var m = ((windowWidth - maxWrapperWidth) / 2);
            $('.full-width')
                .css('margin-left', '-' + (m + wrapperPadding) + 'px')
                .css('margin-right', '-' + (m + wrapperPadding) + 'px')
                .css('padding-left', (m + wrapperPadding) + 'px')
                .css('padding-right', (m + wrapperPadding) + 'px');
        }
    };
    var resizeWindow = function() {
        $(window).resize(function() {
            handleResize();
        });
        // on page load
        handleResize();
    };
    var mobileMenu = function() {
        $('.mobile-menu').click(function(e) {
            $('.main-nav').toggleClass('active');
        });
    };

    var adjustContrast = function() {
        if ($('#contrast select').val() == 'high-contrast') {
            createCookie('contrast', 'high-contrast', '1000');
            $('body').append("<link rel='stylesheet' id='style-contrast-css'  href='css/style-contrast.css' media='all' />");
            $('#contrast select option[value=high-contrast]').attr('selected', 'selected');
        } else {
            createCookie('contrast', 'normal', '1000');
            $('#style-contrast-css').remove();
        }
    };
    var contrastSelectEvent = function() {
        $('#contrast select').on('change', function() {
            adjustContrast();
        });
        if (readCookie('contrast')) {
            var setVal = $('#contrast select').val(readCookie('contrast'));
            $('#contrast select').val(readCookie('contrast'));
        }
        // on page load
        adjustContrast();
    };

    var footerMenuEvents = function() {
        $('.title').on('click', function(e) {
            $(this).closest('.footer-navigation-cell').toggleClass('active');
        });
    };

    var mobileSearchField = function() {
        $('header .search input')
            .on('focus', function(e) {
                $('.mobile-search-btn').addClass('active');
            })
            .on('blur', function(e) {
                $('.mobile-search-btn').removeClass('active');
            });
    };

    var dropdownHeader = function() {
        $('.display-menu li').hover(function() {
            $(this).children('ul').stop().slideToggle(400);
        });
    };
    var searchCarousel = function() {
        $('.search-carousel')
            .on('mouseenter', function() {
                $(this).addClass('hover');
            })
            .on('mouseleave', function() {
                $(this).removeClass('hover');
            });
        // search carousel rotate image
        setInterval(function() {
            // don't change image if hovering over search carousel
            if (!$('.search-carousel.hover').length) {
                // image
                var currentLi = $('.search-carousel .gallery-cell.active');
                var nextLi = currentLi.next('.gallery-cell').length ? currentLi.next() : $('.search-carousel div:first-child');
                currentLi.removeClass('active');
                nextLi.addClass('active');
                // li
                var currentLi = $('.search-carousel ul li.active');
                var nextLi = currentLi.next('.search-carousel ul li').length ? currentLi.next() : $('.search-carousel ul li:first-child');
                currentLi.removeClass('active');
                nextLi.addClass('active');
            }
        }, 5000);
        // handle search gallery bullet clicks
        $('.search-carousel ul li a').on('click', function(e) {
            e.preventDefault();
            var galleryCells = $('.gallery-cell');
            var index = $(".search-carousel ul li a").index(this);
            galleryCells.removeClass('active');
            $(galleryCells[index]).addClass('active');
            $('.search-carousel ul li').removeClass('active');
            $(this).parent('li').addClass('active');
        });
    };

    // Cookie Handler
    var createCookie = function(name, value, days) {
        var expires;

        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toGMTString();
        } else {
            expires = "";
        }
        document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
    };

    var readCookie = function(name) {
        var nameEQ = encodeURIComponent(name) + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
        }
        return null;
    };

    var eraseCookie = function(name) {
        createCookie(name, "", -1);
    };

    var megaMenuEvents = function() {
        $('.has-mega-menu').on('click', function(e) {
            if ( !$(e.target).hasClass('open-mega-menu') ) {
                var openMegaMenu = $('.has-mega-menu.open-mega-menu')
                if (openMegaMenu && openMegaMenu.length > 0) {    
                    $(openMegaMenu).children('.mega-menu').slideToggle()
                    $(openMegaMenu).children('a').toggleClass('active')
                    $(openMegaMenu).toggleClass('open-mega-menu')
                }
            }

            $(e.target).toggleClass('open-mega-menu')
            $(e.target).children('a').toggleClass('active')
            $(e.target).children('.mega-menu').slideToggle({
                start: function () {
                    $(e.target).children('.mega-menu').css({
                        display: "flex"
                    })
                }
            })
            
        })
    }

    return {
        init: function() {
            mobileMenu();
            searchCarousel();
            resizeWindow();
            contrastSelectEvent();
            footerMenuEvents();
            mobileSearchField();
            dropdownHeader();
            megaMenuEvents();
        }
    }
}();

//document ready call init
$(function() {
    Procare.init();
    $(".info-panels p").matchHeight({
        byRow: true,
        property: 'height',
        target: null,
        remove: false
    });

    toggleMegaMenu();
    limitCharacters();
})


function toggleMegaMenu() {
    // toggle hamburger menu class
    var menuHamburger = $('#hamburger')
    if ($(window).width() < 990 && menuHamburger) {
        menuHamburger.on('click', function () {
            $(this).toggleClass('open')
            $('.main-nav').slideToggle('fast')
        })
    }
    $(window).on('resize', function() {
        var win = $(window)
        var mainNav = $('.main-nav')
        if (win.width() > 990) {
            mainNav.css('display', '') 
        } else if (win.width() <= 990) {
            mainNav.css('display', 'none')
        }
        // remove open class
        menuHamburger.removeClass('open')
    })
}

// three column content substring
function limitCharacters() {
    var contentList = document.querySelectorAll('.three-column .rte__content p')
    if (contentList) {
        for (var i = 0; i < contentList.length; i++) {
            var contentText = contentList[i].innerText
            if (contentText.length > 1000) {
                 contentList[i].innerText = contentText.substring(0, 1000) + '...'
            }
        }
    }
}


